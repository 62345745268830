























































































































import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import MoodItem from "@/models/MoodItem";
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import DataTableParameter, {DataTableFilter} from "@/models/DataTableOptions";
import MoodaryDialog from "@/components/moodary/dialog/MoodaryDialog.vue";
import i18n from "@/plugins/i18n";
import {MoodType} from "@/models/MoodType";
import {globalEventBus} from "@/main";

@Component({
    components: {PersistentDialog, MoodaryDialog}
})
export default class MoodaryDataTable extends Vue {
    private loading: boolean = false;
    private dataLoading: boolean = false;
    private selectedEntry!: MoodItem;
    @Ref()
    private moodItemDialog!:MoodaryDialog;
    @Prop({default: new DataTableParameter()})
    private dataParameter!: DataTableParameter;

    private pageCount: number = 0;
    private moodsTotalLength: number = 0;

    @Ref()
    private confirmDialog!: PersistentDialog;
    private headerProps = {
        sortByText: this.$t('general.sortByText')
    };
    private headers: {}[] = [
        {
            text: this.$t('moodary.table.columnDateTime.title'),
            sortable: true,
            value: 'dateTime',
            width: '30%',
        },
        {
            text: this.$t('moodary.table.columnMood.title'),
            sortable: true,
            filterable: true,
            value: 'moodValue',
            width: '20%'
        },
        {
            text: this.$t('moodary.table.columnDetail.title'),
            sortable: false,
            filterable: true,
            value: 'hint',
            width: '50%',
        },
        {text: '', value: 'actions', sortable: false, filterable: false,}
    ];
    private moodEntries: MoodItem[] = [];

    private loadNextPage(isVisible: boolean) {
        if (!isVisible) {
            return;
        }
        if (this.dataParameter.options.page >= this.pageCount) {
            return;
        }
        this.dataParameter.options.page++;
    }
mounted(){
    globalEventBus.$on('swipeLeft',this.addIem);
}
    destroyed(){
        globalEventBus.$off('swipeLeft',this.addIem);
    }
    public addIem(): void {
       this.moodItemDialog.newItem(MoodItem.create(this.$store.getters.diarySettings.moodType));
    }

    viewItem(item) {
        this.selectedEntry = item;
       this.moodItemDialog.viewItem(JSON.parse(JSON.stringify(this.selectedEntry)));
    }

    editItem(item) {
        this.selectedEntry = item;
       this.moodItemDialog.editItem(JSON.parse(JSON.stringify(this.selectedEntry)));
    }

    deleteItem(item) {
        this.confirmDialog.showConfirmation(this.$tc('moodary.confirmation.doDeleteTitle'), this.$tc('moodary.confirmation.doDeleteText'), () => {
            this.$webApi.deleteMoodEntry(item).then((success) => {
                if (success) {
                    const idx = this.moodEntries.findIndex(it => it.id === item.id);
                    if (idx >= 0) {
                        this.moodEntries.splice(idx, 1);
                    }
                }
            })
        });
    }


    @Watch("dataParameter.filter", {deep: true})
    private dataParameterFilterChanged() {
        //console.log("filter changed");
        this.dataParameter.options.page = 1;
        this.getMoodEntries(false);
    }

    @Watch("dataParameter.options.page", {deep: false})
    private pageChanged(oldValue, newValue) {
        //console.log("pageChanged")
        if (oldValue !== newValue) {
            this.getMoodEntries(false);
        }
    }

    @Watch("dataParameter.options", {deep: false})
    private sortByChanged(oldValue, newValue) {
        //console.log("sortByChanged")
        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            this.getMoodEntries(false);
        }
    }

    @Watch("dataParameter.options.sortDesc", {deep: false})
    private sortDescChanged(oldValue, newValue) {
        //console.log("sortDescChanged")
        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            this.getMoodEntries(false);
        }
    }

    private getColor(color: string) {
        if (color) {
            const strColor = color.toString();
            if (strColor.length > 7) {
                return strColor.substr(0, 7);
            } else {
                return strColor;
            }
        }
        return "";
    }

    public getDateTime(item: MoodItem): string {
        if (item?.date) {
            if (item.time) {

                const parsedDate = new Date(item.date + " " + item.time);
                return i18n.d(parsedDate, "dateTime");
            } else {
                try {
                    let parsedDate = new Date(item.date);
                    return i18n.d(parsedDate, "date");
                }catch{
                    console.log("FEHLER" + item.date);
                }

            }
        }
        return '';
    }

    private getIcon(moodValue: number): string {
        const diarySettings = this.$store.getters.diarySettings;
        if(diarySettings.moodType === MoodType.Thumb) {
            if (moodValue >= 3) return 'mdi-thumb-up';
            else return 'mdi-thumb-down';
        } else if (diarySettings.moodType === MoodType.Mood5){
            if (moodValue === 5) return 'mdi-emoticon-excited';
            else if (moodValue === 4) return 'mdi-emoticon-happy';
            else if (moodValue === 3) return 'mdi-emoticon-neutral';
            else if (moodValue === 2) return 'mdi-emoticon-sad';
            else return 'mdi-emoticon-cry';
        } else {
            if (moodValue >= 4) return 'mdi-emoticon-happy';
            else if (moodValue === 3) return 'mdi-emoticon-neutral';
            else return 'mdi-emoticon-sad';
        }
    }

    private async getMoodEntries(initialRequest: boolean = false, addOrDelete: boolean = false, update: number = 0) {
        if (initialRequest) {
            this.dataLoading = true;
        } else {
            this.loading = true;
        }
        const ipp = this.dataParameter.options.itemsPerPage;
        const isFirstRequest = this.dataParameter.options.page == 1;
        //await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
        await this.$webApi.readMoodEntries(this.dataParameter).then((result => {
            if (result?.data) {
                if (isFirstRequest) {
                    this.moodEntries = [];
                }

                this.moodEntries.push(...result.data.items);
                this.moodsTotalLength = result.data.count;

                this.pageCount = Math.ceil(this.moodsTotalLength / this.dataParameter.options.itemsPerPage);
            }
        })).catch((e) => {
            this.moodEntries = [];
        }).finally(() => {
            this.dataParameter.options.itemsPerPage = ipp;
            this.dataLoading = false;
            this.loading = false;
        });
    }

    private async saveEntry(args: { item: MoodItem, isNew: boolean }): Promise<void> {
        if (args.isNew) {
            await this.$webApi.addMoodEntry(new MoodItem(args.item.date, args.item.time, args.item.moodValue, args.item.hint, args.item.imageData, args.item.imageName, args.item.color)).then(() => {
                this.dataParameter.filter = new DataTableFilter();
                this.getMoodEntries(false, true);
            })
        } else {
            await this.$webApi.updateMoodEntry(args.item).then((response) => {
                if (response) {
                    const reloadPage = this.selectedEntry.date !== args.item.date;
                    if (reloadPage) {
                        this.dataParameter.filter = new DataTableFilter();
                    }
                    this.selectedEntry.date = args.item.date;
                    this.selectedEntry.time = args.item.time;
                    this.selectedEntry.moodValue = args.item.moodValue;
                    this.selectedEntry.hint = args.item.hint;
                    this.selectedEntry.imageData = args.item.imageData;
                    this.selectedEntry.imageName = args.item.imageName;
                    this.selectedEntry.color = args.item.color;
                    this.updateMoodEntry(args.item);
                } else {
                    this.$emit("error");
                }
            }).catch(() => {
                this.$emit("error");
                window.scrollTo(0, 0);
            });
        }
    }

    private async updateMoodEntry(moodItem: MoodItem): Promise<void> {
        const item = this.moodEntries.find((entry) => entry.id === moodItem.id);
        if (item) {
            item.color = moodItem.color;
            item.date = moodItem.date;
            item.moodValue = moodItem.moodValue;
            item.time = moodItem.time;
            item.hint = moodItem.hint;
            item.imageData = moodItem.imageData;
            item.imageName = moodItem.imageName;
        }
    }
}
