
































































import {Component, Ref, Watch, Vue} from 'vue-property-decorator';
import MoodItem from "@/models/MoodItem";
import DataTableParameter, {DataTableFilter} from "@/models/DataTableOptions";
import FilterMenu from "@/components/moodary/dialog/FilterMenu.vue";
import MoodaryDataTable from "@/components/moodary/MoodaryDataTable.vue";
import MoodaryDataCalendar from "@/components/moodary/MoodaryDataCalendar.vue";
import ViewDesign from "@/components/global/ViewDesign.vue";

@Component({
    components: {ViewDesign, FilterMenu, MoodaryDataTable, MoodaryDataCalendar},
})
export default class Moodary extends Vue {
    //member
    @Ref('dataView')
    private dataTable!: MoodaryDataTable;
    @Ref('dataView')
    private dataCalendar!:MoodaryDataCalendar;
    private dataTableParameter: DataTableParameter = new DataTableParameter();
    private showError: boolean = false;

    private get isListView(): boolean {
        if(this.$route.params.listView) {
            return this.$route.params.listView.toLowerCase() === "list";
        }
        return this.$store.getters.showList;
    }

    async mounted(){
        //globalEventBus.$on("userSettingsChanged", this.userSettingsChanged);
    }

    destroyed(){
        //globalEventBus.$off("userSettingsChanged", this.userSettingsChanged);
    }

    constructor() {
        super();
    }

    private filterChanged(filter:DataTableFilter): void {

    }

    private clearValue() {
        this.dataTableParameter.filter = new DataTableFilter();
        //this.getMoodEntries(false);
    }

    addIem() {
        this.dataTable.addIem();
    }

    @Watch('isListView')
    private isListViewChanged(){
        this.dataTableParameter=new DataTableParameter();
    }

    public getDateTimeNumber(item: MoodItem): number {
        if (item.time) {
            return Date.parse(item.date + " " + item.time);
        }
        return Date.parse(item.date);
    }

    private changeView(): void {
        const listView = !this.isListView;
        this.$store.commit("SET_SHOWLIST",listView);
        this.$router.push("/" + this.$store.getters.lang + "/diary/" + (listView ? "list": "calendar"));
    }

    private get viewInstance() {
        if(this.isListView) {
            return () => { return import("@/components/moodary/MoodaryDataTable.vue") };
        } else {
            return () => { return import("@/components/moodary/MoodaryDataCalendar.vue") };
        }
    }
}
