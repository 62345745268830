






















































































import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import MoodItem from "@/models/MoodItem";
import moment from "moment-mini";
import DateUtility from "@/assets/utils/DateFormatUtil";
import DataTableParameter, {DataTableFilter} from "@/models/DataTableOptions";
import DatePicker from "@/components/global/DatePicker.vue";
import SeriesDialog from "@/components/trends/dialog/SeriesDialog.vue";
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import MoodaryDialog from "@/components/moodary/dialog/MoodaryDialog.vue";
import {MoodType} from "@/models/MoodType";
import {globalEventBus} from "@/main";


@Component({
    components: {DatePicker, PersistentDialog, MoodaryDialog, SeriesDialog}
})
export default class MoodaryDataCalendar extends Vue {
    @Ref()
    private seriesDialog!:SeriesDialog;
    @Ref()
    private moodItemDialog!:MoodaryDialog;
    private startEndCalendar:any;
    @Prop({default: new DataTableParameter()})
    private dataParameter!: DataTableParameter;

    private events:any[] = [];
    private dataLoading: boolean = false;
    private date:string = this.getInitialDate();
    private dateCopy:string='';
    private showDate:boolean = false;
    private formattedDate: string = '';
    @Ref()
    private confirmDialog!: PersistentDialog;
    destroyed(){
        globalEventBus.$off('swipeRight',()=>console.log('right'));
        globalEventBus.$off('swipeLeft',()=>console.log('left'));
    }
    mounted(){
        this.date= DateUtility.getDateString(new Date(this.$store.getters.calendarViewStart));
        this.dateChanged();
        globalEventBus.$on('swipeRight',this.previousMonth);
        globalEventBus.$on('swipeLeft',this.nextMonth);
    }
    private previousMonth() {
        const selectedMonth = new Date (this.dateCopy);
        this.date = moment(selectedMonth.setMonth(selectedMonth.getMonth() -1)).format("YYYY-MM");
    }
    private nextMonth() {
        const selectedMonth = new Date (this.dateCopy);
        const nextMonth = new Date(selectedMonth.setMonth(selectedMonth.getMonth() + 1));
        if(new Date() > nextMonth) {
            this.date = moment(nextMonth).format("YYYY-MM");
        }
    }
    private get maxDate(): string {
        return MoodItem.getFormattedDate(new Date());
    }

    @Watch("date")
    private dateChanged(): void {
        this.dateCopy= moment(this.date).format("YYYY-MM");
        let parsedDate = new Date(this.date);
        this.formattedDate = this.$d(parsedDate, "month");
    }

    @Watch("dataParameter", {deep: true})
    private dataFilterChanged() {
        this.getEvents(this.startEndCalendar);
    }

    private clickDate(args:any) :void {
        const date = new Date(args.date);
        if(date <= new Date()) {
            if (date.getDate() <= Date.now()) {
                const startDate = new Date(this.startEndCalendar.start.date);
                const endDate = new Date(this.startEndCalendar.end.date);
                if (date >= startDate && date <= endDate) {
                    const item = MoodItem.create(this.$store.getters.diarySettings.moodType);
                    item.date = MoodItem.getFormattedDate(date);
                    this.moodItemDialog.newItem(item, this.startEndCalendar);
                }else {
                    this.date = moment(date).format("YYYY-MM-DD");
                }
            }
        }
    }

    private async getEvents (args:{ start, end }):  Promise<void> {

        function getMoodValue(moodType:MoodType, moodValue:number) {
            let theValue = 0 ;
            if(moodType === MoodType.Mood5) {
                theValue = moodValue;
            }
            else if(moodType === MoodType.Mood3) {
                if(moodValue >= 4)
                    theValue = 4;
                if(moodValue === 3)
                    theValue = 3;
                if(moodValue <= 2)
                    theValue =  2;
            }
            else {
                if(moodValue >= 3){
                    theValue= 4;
                } else {
                    theValue= 2;
                }
            }
            return theValue;
        }

        try{
            const diarySettings = this.$store.getters.diarySettings;

            this.dataLoading=true;
            this.startEndCalendar=args;
            this.$store.commit("SET_CALENDARVIEWSTART",args.start.date);
            await this.$webApi.readMoodEntriesRange(args.start.date,args.end.date, this.dataParameter.filter).then((response)=>{
                try {
                    let groupedData = response.data.reduce(function (l, r) {
                        // construct a unique key out of the properties we want to group by
                        let key = r.date;
                        // check if the key is already known
                        if (typeof l[key] === "undefined") {
                            // init with an "empty" object
                            l[key] = {
                                sum: 0,
                                count: 0
                            };
                        }
                        // sum up the values and count the occurences
                        l[key].sum += r.moodValue;
                        l[key].color = r.color;
                        l[key].count += 1;
                        return l;
                    }, {});

                    let avgGroupedData = Object.keys(groupedData).map(function (key) {
                        return {
                            date: key,
                            color: groupedData[key].color,
                            count: groupedData[key].count,
                            value: getMoodValue(diarySettings.moodType, Math.round(groupedData[key].sum / groupedData[key].count))
                        };
                    });

                    const events: any[] = [];
                    for (let i = 0; i < avgGroupedData.length; i++) {
                        events.push({
                            data: avgGroupedData[i],
                            timed: false,
                            start: avgGroupedData[i].date,
                            end: avgGroupedData[i].date
                        })
                    }
                    this.events = events;
                }catch(e) {
                    console.log(e);
                }
            });
        }finally {
            this.dataLoading=false;
        }
    }

    private getInitialDate(): string {
        let date = this.$store.getters.calendarViewStart;
        if(!date) {
            date = DateUtility.getDateString(new Date());
        }
        return date;
    }

    private itemClicked(event:any): void {
        if(event && event.data && event.data.date && event.data.count && event.data.count > 0) {
            this.seriesDialog.showDialog(new Date(event.data.date));
        }
    }

    private getColor(event: any) {
        if(event && event.data) {
            const data = event.data;
            if (data.color && data.count == 1) {
                const strColor = data.color.toString();
                if (strColor.length > 7) {
                    return strColor.substr(0, 7);
                } else {
                    return strColor;
                }
            }
        }
        return "";
    }

    private getIcon(event: any): string {
        if(event && event.data && event.data.value) {
            const diarySettings = this.$store.getters.diarySettings;
            const moodValue = event.data.value;
            if(diarySettings.moodType === MoodType.Thumb) {
                if (moodValue > 2) return 'mdi-thumb-up';
               return 'mdi-thumb-down';
            } else if (diarySettings.moodType === MoodType.Mood5){
                if (moodValue === 5) return 'mdi-emoticon-excited';
                else if (moodValue === 4) return 'mdi-emoticon-happy';
                else if (moodValue === 3) return 'mdi-emoticon-neutral';
                else if (moodValue === 2) return 'mdi-emoticon-sad';
                else return 'mdi-emoticon-cry';
            } else {
                if (moodValue >= 4) return 'mdi-emoticon-happy';
                else if (moodValue === 3) return 'mdi-emoticon-neutral';
                else return 'mdi-emoticon-sad';
            }
        } else {
             return 'mdi-emoticon-neutral';
        }

    }

    private get startDate() : string {
        return moment(this.date).format("YYYY-MM-DD");
    }

    public addIem(): void {
        const item = MoodItem.create(this.$store.getters.diarySettings.moodType);
        if(this.startEndCalendar && this.startEndCalendar.start && this.startEndCalendar.start.date) {
            item.date = MoodItem.getFormattedDate(new Date(this.startEndCalendar.start.date))
        }
        this.moodItemDialog.newItem(item,this.startEndCalendar);
    }

    private async deleteEntry(args:{item:MoodItem}): Promise<void>{
        this.confirmDialog.showConfirmation(this.$tc('moodary.confirmation.doDeleteTitle'), this.$tc('moodary.confirmation.doDeleteText'), async() => {
            try {
                await this.$webApi.deleteMoodEntry(args.item);
                await this.getEvents(this.startEndCalendar);
            } catch {

            } finally {
                await this.seriesDialog.refresh();
            }
        });
    }

    private async saveEntry(args: { item: MoodItem, isNew: boolean }): Promise<void> {
        try{
            this.dataLoading = true;
            if (args.isNew) {
                await this.$webApi.addMoodEntry(new MoodItem(args.item.date, args.item.time, args.item.moodValue, args.item.hint, args.item.imageData, args.item.imageName, args.item.color)).then(() => {
                    this.dataParameter.filter = new DataTableFilter();
                    this.getEvents(this.startEndCalendar);
                })
            }else{
                this.$webApi.updateMoodEntry(args.item).then((response) => {
                    if (response) {
                        this.getEvents(this.startEndCalendar);
                    }
                });
            }
        }finally {
            this.dataLoading = false;
        }

    }

    private showMoodItem(item: MoodItem): void {
        try {
            this.dataLoading = true;
            this.moodItemDialog.viewItem(item);
        }finally {
            this.dataLoading = false;
        }

    }
}
