




















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {DataTableFilter} from "@/models/DataTableOptions";
import MoodItem from "@/models/MoodItem";
import MoodSelect from "@/components/moodary/MoodSelect.vue";

@Component({
    components: {MoodSelect},
})
export default class FilterMenu extends Vue {
    @Prop({required: true})
    private value!: DataTableFilter;

    private valueCopy: DataTableFilter = new DataTableFilter();
    private open: boolean = false;
    private dateMenu: boolean = false;

    private get maxDate(): string {
        return MoodItem.getFormattedDate(new Date());
    }
    // private get minDate(): string {
    //     const date = new Date();
    //     return MoodItem.getFormattedDate(new Date(date.getFullYear(), date.getMonth(), 1));
    // }
    @Watch("value", {deep: true})
    openOnChange() {
        this.valueCopy = JSON.parse(JSON.stringify(this.value));
        this.$emit("filterChanged",this.valueCopy);
    }

    private clearDate() {
        this.valueCopy.date = "";
    }

    private get formattedDate() {
        if (this.valueCopy?.date) {
            try {
                return this.$d(new Date(this.valueCopy.date), "date");
            } catch {
            }
        }
        return "";
    }

    private set formattedDate(formatted: string) {
        this.valueCopy.date = formatted;
    }

    private save() {
        this.$emit("input", this.valueCopy)
        this.open = false;
    }

    private clear() {
        this.$emit("resetInput")
        this.open = false;
    }

    public getDateString(date: Date): string {
        return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    }

    private get isEmpty() {
        return !this.valueCopy.date && !this.valueCopy.detail && this.valueCopy.moodValue < 0;
    }
}
